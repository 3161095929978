<template>
  <div class="edito">
    <img width="100%" src="../assets/images/bdform.png" />
    <br />
    <br />
    <p />
    <p>Désolé, notre offre satisfait ou remboursés est terminée.</p>
    <br />
    <br />
  </div>
</template>
<script>
export default {
  name: 'Finished',
}
</script>
